import React from "react";
//import ScrollAnimation from "../../components/scrollanimation/ScrollAnimation";
//import Grid from "../../components/grid/Grid";

function About() {
    return (
    <>
       <div style={{color:"white"}}>
        <h1 style={{textAlign:"center"}}>Education</h1>
        <div style={{border: "2px white solid", borderRadius: "1%", padding: "20px", color: "white",width: "70%"}}>
                <h1>Florida Atlantic University High School</h1>
                <p>Aug 2023 - present</p>
            </div>
       </div>
    </>
    )
}

export default About;